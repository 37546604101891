body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  right: 0;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  right: 0;
}

body {
  background: #F5F5F5;
}